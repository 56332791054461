
import {
	Message
} from 'element-ui'


import {
	handlePageQueryPlayers,
	handleManualRealNameAuthPlayer
} from '@manage/api/bm/playermanage';

export default {
	name: 'usermanage',
	components: {},
	data() {
		return {
			realNameAuthStatuses: ["未认证", "已认证", "认证失败"],
			realNameAuthStatuseList: [{ value: "", label: "全部" },{ value: "0", label: "未认证" }, { value: "1", label: "已认证" }, { value: "2", label: "认证失败" }],
			dialogVisible: false,
			editUserForm: {},
			pageSize: 30,
			currPage: 1,
			totalCount: 0,
			queryForm: { realNameAuthStatus: '' },
			tableData: [],

		}
	},
	watch: {

	},
	created() {
		this.fastQuery();
	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		handleSizeChange(pageSize) { //改变页面大小
			this.pageSize = pageSize;
			this.pageQuery();
		},
		handleCurrentChange(currPage) { //跳转到某一页
			this.currPage = currPage;
			this.pageQuery();
		},
		fastQuery() {
			this.currPage = 1;
			this.pageQuery();
		},
		pageQuery() {
			handlePageQueryPlayers({
				pageSize: this.pageSize,
				currPage: this.currPage,
				con: this.queryForm.con,
				realNameAuthStatus: this.queryForm.realNameAuthStatus,
				type: '1',
				sortWay: [{
					fieldName: 'createDate',
					sortWay: 'DESC'
				}]
			}).then(res => {
				if (res.status == 200) {

					this.tableData = res.data;
					this.currPage = res.currPage;
					this.pageSize = res.pageSize;
					this.totalCount = res.totalCount;
					this.$nextTick(() => {
						this.$refs.table.bodyWrapper.scrollTop = 0
					});
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		manualRealNameAuthPlayer(id) { //手动实名认证选手
			handleManualRealNameAuthPlayer(id).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5 * 1000
					})
					this.pageQuery();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},

		openModifyDialog() { //打开修改对话框
			// this.dialogVisible = true;
			// handleViewUserDetails(id).then(res => {
			// 	if (res.status == 200) {
			// 		this.editUserForm = res.data.user;
			// 	} else {
			// 		Message({
			// 			message: res.msg,
			// 			type: 'error',
			// 			duration: 5 * 1000
			// 		})
			// 	}
			// });
		},
		submitInfo() { //保存信息
			// handleSaveUserInfo(this.editUserForm).then(res => {
			// 	if (res.status == 200) {
			// 		Message({
			// 			message: res.msg,
			// 			type: 'success',
			// 			duration: 5 * 1000
			// 		})
			// 		this.dialogVisible = false;
			// 		this.fastQuery();
			// 		this.editUserForm = {};
			// 	} else {
			// 		Message({
			// 			message: res.msg,
			// 			type: 'error',
			// 			duration: 5 * 1000
			// 		})
			// 	}
			// });
		},
		openAddUserDialog() {
			this.editUserForm = {
				headerURL: ''
			};
			this.dialogVisible = true;
		},
		resetForm() {
			this.queryForm = {};
			this.fastQuery();
		},
	}
}