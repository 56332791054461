import request from '@manage/utils/request'

export function handlePageQueryPlayers(data) { //分页查询用户
	return request({
		url: 'admin/playermanage/pageQueryPlayers',
		method: 'post',
		data
	})
}


export function handleManualRealNameAuthPlayer(id) { //手动实名认证选手
	return request({
		url: 'admin/playermanage/manualRealNameAuthPlayer',
		method: 'post',
		params: {
			id: id
		}
	})
}
